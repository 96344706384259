import { $adminHost, $host } from './index';
// @route   GET /carusel
// @desc    Get carusel
// @access  Public
export const getCaruselMain = async () => {
    // if(admin){
    //   const res = await $adminHost.get('carusel').catch(err => {
    //     throw err;
    //   });
    //   return res;
    // }
    // if(!admin){
      const res = await $host.get('carusels/get/').catch(err => {
        console.log(res)
        throw err;
      });
      return res;
    // }
  };