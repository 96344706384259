import React from 'react';
import ContainerPage from '../../components/ContainerPage/ContainerPage';
import OrdersList from './OrdersList/OrdersList';
import BurgerMenu from '../BurgerMenu/BurgerMenu';

const OrdersAdmin = () => {
  return (
    <ContainerPage>
      <BurgerMenu />

      <OrdersList />
    </ContainerPage>
  );
};

export default OrdersAdmin;
