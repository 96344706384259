import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import CardItem from "./Card/CardItem";
import "./ProductsCards.scss";
import { getWishListSelector } from "../../store/wishList/selectors";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";

const ProductsCards = ({ products, categoriesMenu }) => {
    const auth = useContext(AuthContext);
    const [productsCards, setProductsCards] = useState([]);
    const [wishProduct, setWishProduct] = useState({});
    const storeWishlist = useSelector(getWishListSelector);

    useEffect(() => {
        const wishlist = {};
        
        // Check Redux state for wishlist
        if (storeWishlist && storeWishlist?.products?.length > 0) {
            storeWishlist.products.forEach((prod) => {
                wishlist[prod._id] = true;
            });
        }
        
        // Check Local Storage if Redux wishlist is not available
        else if (!storeWishlist && localStorage.getItem("wishlist")) {
            const localStorageWishlist = JSON.parse(localStorage.getItem("wishlist"));
            if (localStorageWishlist?.products?.length > 0) {
                localStorageWishlist.products.forEach((prod) => {
                    wishlist[prod._id] = true;
                });
            }
        }
        
        setWishProduct(wishlist);
    }, [storeWishlist]);

    useEffect(() => {
        if (products.length > 0) {
            const list_products = products.map((item) => (
                <li key={item._id} className="catalog-grid__cell">
                    <CardItem auth={auth} item={item} wishProduct={wishProduct} />
                </li>
            ));
            setProductsCards(list_products);
        }
    }, [products, wishProduct]);

    // Show loader if there are no products or categories
    if (products.length === 0 || !categoriesMenu || categoriesMenu.length === 0) {
        return (
            <>
                <Loader />
                <h1>Товари цієї категорії в дорозі...</h1>
            </>
        );
    }

    return (
        <ul className="catalog-grid">
            {products.length > 0 ? productsCards : <p>Товарів немає, ми вирішуємо цю проблему.</p>}
        </ul>
    );
};

export default ProductsCards;
