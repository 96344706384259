import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import RequiredIndicator from '../RequiredIndicator/RequiredIndicator';

const CategoriesForm = ({ initialValues, onSubmit, edit, create, discounts }) => {
    const [discountsOption, setDiscountsOption] = useState();

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required("Обов'язкове поле"),
            title: Yup.string().required("Обов'язкове поле"),
            description: Yup.string().notRequired(),
            active: Yup.boolean().required("Обов'язкове поле"),
            isMenu: Yup.boolean().required("Обов'язкове поле"),
            discount: Yup.string().oneOf(discounts.map((option) => option._id), "").notRequired(),
        }),
        onSubmit: (values, { resetForm }) => {
            onSubmit(values);
            resetForm();
        },
    });

    useEffect(() => {
        formik.setValues(initialValues);
    }, [initialValues]);

    useEffect(() => {
        if (discounts.length > 0) {
            const options = discounts.map((discount, index) => {
                return <option key={index} value={discount._id}>{discount.name}</option>;
            });
            setDiscountsOption(options);
        }
    }, [discounts]);

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Label>Назва <RequiredIndicator /></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="title">
                    <Form.Label>Назва для пошуку <RequiredIndicator /></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter title"
                        name="title"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.title}
                    />
                    {formik.touched.title && formik.errors.title ? (
                        <div className="text-danger">{formik.errors.title}</div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="description">
                    <Form.Label>Опис</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter description"
                        name="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                    />
                </Form.Group>

                <Form.Group controlId="discount" className='mb-3'>
                    <Form.Label>Категорія товару для знижки</Form.Label>
                    <Form.Select
                        name="discount"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.discount || "0"}
                    >
                        <option value='0'>Обрати</option>
                        {discountsOption}
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId="active">
                    <Form.Label>Active <RequiredIndicator /></Form.Label>
                    <Form.Check
                        type="switch"
                        id="active-checkbox"
                        name="active"
                        onBlur={formik.handleBlur}
                        checked={formik.values.active}
                        onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                    />
                    {formik.touched.active && formik.errors.active ? (
                        <div className="text-danger">{formik.errors.active}</div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="isMenu">
                    <Form.Label>Частина меню <RequiredIndicator /></Form.Label>
                    <Form.Check
                        type="switch"
                        id="isMenu-checkbox"
                        name="isMenu"
                        onBlur={formik.handleBlur}
                        checked={formik.values.isMenu}
                        onChange={(e) => formik.setFieldValue('isMenu', e.target.checked)}
                    />
                    {formik.touched.isMenu && formik.errors.isMenu ? (
                        <div className="text-danger">{formik.errors.isMenu}</div>
                    ) : null}
                </Form.Group>

                <Button variant="dark" type="submit">
                    {create && "Створити"}
                    {edit && "Редагувати"}
                </Button>
            </Form>
        </div>
    );
};

export default CategoriesForm;
