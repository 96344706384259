import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { replace } from '../../../utils/func';
import styles from './ProductInfo.module.scss';
const PRODUCT_ROUTE = "products/item/"; 

const ProductInfo = ({ product }) => {
  return (
    <div className={styles.productItem}>
      <div className={styles.productImage}>
        <NavLink to={`/${PRODUCT_ROUTE}${product._id}`} target='_blank'>
          <img src={product.imageFirst} width={100} height={100} alt='productImage' />
        </NavLink>
      </div>
      <div>
        <NavLink to={`/${PRODUCT_ROUTE}${product._id}`} target='_blank'>
          <div className={`${styles.bold} ${styles.productName}`}>
            {product.name}, {product.color}
          </div>
        </NavLink>
        <div className={styles.productPrice}>
          <div>
            Ціна: <span className={styles.bold}>{replace(product.currentPrice)} грн.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductInfo.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductInfo;
