import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import RequiredIndicator from '../RequiredIndicator/RequiredIndicator';
const BrandForm = ({edit, initialValues, onSubmit }) => {
    const [files, setFiles] = useState([]);
    
    const formik = useFormik({
        initialValues:initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required("Обов'язкове поле"),
            imageUrl: Yup.string(),
            imageUrlChange: Yup.string(),
            active: Yup.boolean().default(false),
        }),
        onSubmit: (values, { resetForm }) => {
            const formData = new FormData();
            formData.append('active', values.active);
            formData.append('name', values.name);
            formData.append('imageUrl', values.imageUrl);
            formData.append('file', files);
            onSubmit(formData);
            resetForm();
        },
    });

    useEffect(() => {
        formik.setValues(initialValues);
    }, [initialValues]);

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
            {formik.values.imageUrl && <div className='product__img'>
                <p>Стара картинка</p>
                <img src={formik.values.imageUrl} alt="Стара картинка" /></div>}

                <Form.Group controlId="name">
                    <Form.Label>Назва <RequiredIndicator /></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        isInvalid={formik.touched.name && formik.errors.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="imageUrlChange">
                    <Form.Label>Картинка товару</Form.Label>
                    <Form.Control
                        type="file"
                        name={`imageUrlChange`}
                        onChange={event => {
                            formik.setFieldValue(`imageUrlChange`, "file");
                            setFiles(event.target.files[0])
                        }}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.imageUrlChange && formik.errors.imageUrlChange && (
                        <div className="text-danger">{formik.errors.imageUrlChange}</div>
                    )}
                    {formik.values.imageUrl && (
                        <div style={{width:100+"%"}}>
                        </div>
                    )}
                </Form.Group>

                <Form.Group controlId="active">
                    <Form.Check
                        type="switch"
                        id="active-checkbox"
                        label="Активувати"
                        name="active"
                        onBlur={formik.handleBlur}
                        checked={formik.values.active}
                        onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                    />
                    {formik.touched.active && formik.errors.active ? (
                        <div className="text-danger">{formik.errors.active}</div>
                    ) : null}
                </Form.Group>

                <Button variant="dark" type="submit">
                    {edit ? "Редагування бренду" : "Створити бренд"} 
                </Button>
            </Form>
        </div>
    );
};

export default BrandForm;
