import React from "react";
import "./Burger.scss";

const Burger = ({ open, setOpen}) => {
  const buttonClass = open ? "burger-button open" : "burger-button";
  return (
    <button className={buttonClass} 
    onClick={() => setOpen(!open)}
    onMouseEnter={() => setOpen(true)}  // Open on hover
      >
      <div />
      <div />
      <div />
    </button>
  );
};

export default Burger;