import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './CarouselItemContent.module.scss';

function CarouselItemContent({ slide }) {
  const SLIDE_ROUTE = slide.slideType === 'product' ? 'products/item/' : 'blog/';
  return (
    <div className={styles.carouselItemWrapper}>
      <NavLink to={`/${SLIDE_ROUTE}${slide._id}`} target="_blank">
        <img
          className="d-block w-100"
          src={slide.imageFirst || slide.imageUrl || '/path-to-placeholder-image.jpg'} // Fallback image
          alt={slide.description || `slide ${slide._id}`} // Fallback alt text with ID
          loading="lazy" // Lazy loading for performance
        />
      </NavLink>
    </div>
  );
}

export default CarouselItemContent;
