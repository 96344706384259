import React, { useEffect, useState } from "react";
import "./Menu.scss";
import { NavLink } from "react-bootstrap";
import ua from "../../../language/ua.json";
import { Link } from "react-router-dom";

const Menu = ({ open,setOpen, menuList}) => {
  const [menuItems, setMenuItems] = useState([]);
  const handleClickMenu = () => {
    setOpen(false);
  };
  const menu = () => {
    const links = menuList.map((item, index) => (
      <Link key={index} to={"../" + item.url} onClick={handleClickMenu}>{ua[item.name]}</Link>
    ));
    setMenuItems(links);
  }
  
  // Викликаємо функцію menu() один раз при монтуванні компонента
  useEffect(() => {
    if(menuList.length > 0){
      menu(); 
    }
  }, [menuList]);

  return(
    <nav className={`menu ${open ? 'open' : ''}`}>
      <div className="menu__wrapper">
        {menuItems}
      </div>
    </nav>
  )
}
  
export default Menu;