import React, {  useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import "./ProductForm.scss";
import RequiredIndicator from '../RequiredIndicator/RequiredIndicator';

const ProductForm = ({edit, initialValues, onSubmit, categoriess, brands, countrys}) => {
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [optionsBrands, setOptionsBrands] = useState([]);
  const [categories, setCategories] = useState(categoriess);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [countrysOption, setCountrysOption] = useState();
  const [files, setFiles] = useState([]);
  // Function to set image preview
  // const setImagePreview = (index, imageUrl, namefield) => {
  //   const previews = [...imagePreviews];
  //   previews[index] = { ...previews[index], [namefield]: imageUrl };
  //   setImagePreviews(previews);
  // };
 
  useEffect(() => {
    if (categories.length > 0) {
      const options = categories.map((category) => {
        return { value: category._id, label: category.name };
      });
      setOptionsCategories(options);
    }
    if (brands.length > 0) {
      const options = brands.map((brand) => {
        return { value: brand._id, label: brand.name };
      });
      setOptionsBrands(options);
    }
    if(countrys.length > 0 ) {
      const options = countrys.map((country, index) => {
        return <option key={index} value={country.name}>{country.name}</option>
      });
      setCountrysOption(options)
    }
  }, [countrys]);

// const upload=() => {
//   const formData = new FormData();
//   // for(var i = 0 ; i < files.length; i++){
//     formData.append(`multi-files`,files)
// // }
// if(!files) return; 
//   // formData.append("file", file);
//   axios.post("/api/admin/products/create", formData)
//   .then(res => {})
//   .catch(er => console.log(er))
// }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      enabled: Yup.boolean()
        .oneOf([true, false], 'Показує, чи є товар доступним для продажу чи ні.')
        .required("Required")
        .default(false),
      name: Yup.string().required("Обов'язкове поле"),
      description: Yup.string().notRequired(),
      imageFirst: Yup.string(),
      currentPrice: Yup.string().required("Обов'язкове поле"),
      previousPrice: Yup.string().notRequired(),
      categories: Yup.string()
        .oneOf(optionsCategories.map((option) => option.value), "")
        .required("Обов'язкове поле"),
      imageUrls: Yup.string().notRequired(),
      //  Yup.object(),
      // .of(
      //   Yup.object().shape({
      //     smallImage: Yup.string().required("Маленька картинка обов'язкова"),
      //     largeImage: Yup.string().required("Велика картинка обов'язкова")
      //   })
      // ).required("Картинка обов'язкова")
      quantity: Yup.string().required("Обов'язкове поле"),
      sizes: Yup.string(),
      productUrl: Yup.string().notRequired(),
      brand: Yup.string(),
      manufacturer: Yup.string().notRequired(),
      manufacturerCountry: Yup.string().notRequired(),
      seller: Yup.string().notRequired(),
      discount: Yup.string().notRequired(),
      bottling: Yup.boolean()
      .oneOf([true, false], 'Показує, чи є товар розливним')
      .required("Обов'язкове поле"),
      quantityMl: Yup.string().notRequired(),
      toCarusel: Yup.boolean(),
    }),
    onSubmit: async (values, { resetForm  }) => {
      const formData = new FormData();
      formData.append('enabled', values.enabled);
      formData.append('name', values.name);
      formData.append('imageFirst', values.imageFirst);
      formData.append('description', values.description);
      formData.append('currentPrice', values.currentPrice);
      formData.append('previousPrice', values.previousPrice);
      formData.append('categories', values.categories);
      formData.append('quantity', values.quantity);
      formData.append('sizes', values.sizes);
      formData.append('productUrl', values.productUrl);
      formData.append('brand', values.brand);
      formData.append('manufacturer', values.manufacturer);
      formData.append('manufacturerCountry', values.manufacturerCountry);
      formData.append('discount', values.discount || 0);
      formData.append('seller', values.seller);
      formData.append('bottling', values.bottling);
      formData.append('quantityMl', values.quantityMl);
      formData.append('file', files);
      formData.append('toCarusel', values.toCarusel);

      // добавьте остальные поля формы аналогичным образом
      onSubmit(formData);
      resetForm();
      setFiles([]);
    }
  });
  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  // const addImageField = () => {
  //   formik.setFieldValue('imageUrls', [...formik.values.imageUrls, { smallImage: '', largeImage: '' }]);
  //   setImagePreviews([...imagePreviews, '']);
  // };
  
  // const delImageField = (index) => {
  //   const updatedImageUrls = [...formik.values.imageUrls];
  //   updatedImageUrls.splice(index, 1);
  //   formik.setFieldValue('imageUrls', updatedImageUrls);
  // };
  return (
    <div>
      {/* <div>
        <input type="text" name="names" onChange={change}/>
        <input type="file" onChange={(e)=> setFiles(e.target.files)} accept='image/*'  multiple/>
        <button type="button" onClick={upload}>Upload</button>
      </div> */}
      <Form onSubmit={formik.handleSubmit}>
        {formik.values.imageFirst && <div className='product__img'>
          <p>Стара картинка</p>
          <img src={formik.values.imageFirst} alt="Стара картинка" /></div>}
        <Form.Group controlId="name">
          <Form.Label>Назва товару <RequiredIndicator /></Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </Form.Group>

        <Form.Group controlId="description">
          <Form.Label>Опис товару</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="text-danger">{formik.errors.description}</div>
          ) : null}
        </Form.Group>
        
        <Form.Group controlId="categories"  className='mb-3'>
          <Form.Label>Категорія товару <RequiredIndicator /></Form.Label>
          <Form.Select
            name="categories"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.categories}
          >
            <option value="" >Обрати</option>
          {optionsCategories.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
          ))}
          </Form.Select>
          {formik.touched.categories && formik.errors.categories && formik.values.categories == false  ? (
            <div className="text-danger">{formik.errors.categories}</div>
          ) : null}
        </Form.Group>

        <Form.Group controlId="imageUrls">
          <Form.Label>Картинкa товару</Form.Label>
          <Form.Control
            type="file"
            name={`imageUrls`}
            onChange={event => {
              const file = event.currentTarget.files[0];
              formik.setFieldValue(`imageUrls`, "file");
              setFiles(event.target.files[0])
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.imageUrls && formik.errors.imageUrls && (
            <div className="text-danger">
              {formik.errors.imageUrls}
            </div>
          )}
          {formik.values.imageUrls && (
            <div style={{width:100+"%"}}>
              {/* <img style={{width:100+"%"}} src={imagePreviews[0]?.smallImage} alt="Small" /> */}
            </div>
          )}
        </Form.Group>

        <Form.Group controlId="brand">
          <Form.Label>Бренд </Form.Label>
          <Form.Select
            name="brand"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.brand}
          >
            <option value="" >Обрати</option>
          {optionsBrands.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
          ))}
          </Form.Select>
          {formik.touched.brand && formik.errors.brand && formik.values.brand == false  ? (
            <div className="text-danger">{formik.errors.brand}</div>
          ) : null}
        </Form.Group>  

        <Form.Group controlId="currentPrice">
          <Form.Label>Поточна ціна <RequiredIndicator /></Form.Label>
          <Form.Control
            type='text'
            name="currentPrice"
            value={formik.values.currentPrice}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.currentPrice && formik.errors.currentPrice ? (
            <div className="text-danger">{formik.errors.currentPrice}</div>
          ) : null}
        </Form.Group>
                
        <Form.Group controlId="previousPrice">
          <Form.Label>Попередня ціна</Form.Label>
          <Form.Control
            type='text'
            name="previousPrice"
            value={formik.values.previousPrice}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.previousPrice && formik.errors.previousPrice ? (
            <div className="text-danger">{formik.errors.previousPrice}</div>
          ) : null}
        </Form.Group>
                
        <Form.Group controlId="quantity">
          <Form.Label>Кількість <RequiredIndicator /></Form.Label>
          <Form.Control
            type='text'
            name="quantity"
            value={formik.values.quantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.quantity && formik.errors.quantity ? (
            <div className="text-danger">{formik.errors.quantity}</div>
          ) : null}
        </Form.Group>
                
        <Form.Group controlId="seller">
          <Form.Label>Продавець товару</Form.Label>
          <Form.Control
            type='text'
            name="seller"
            value={formik.values.seller}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.seller && formik.errors.seller ? (
            <div className="text-danger">{formik.errors.seller}</div>
          ) : null}
        </Form.Group>
                
        <Form.Group controlId="manufacturer">
          <Form.Label>Виробник</Form.Label>
          <Form.Control
            type='text'
            name="manufacturer"
            value={formik.values.manufacturer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.manufacturer && formik.errors.manufacturer ? (
            <div className="text-danger">{formik.errors.manufacturer}</div>
          ) : null}
        </Form.Group>
                
        <Form.Group controlId="manufacturerCountry">
          <Form.Label>Країна виробник</Form.Label>
          <Form.Control
            list='countrys'
            type='text'
            name="manufacturerCountry"
            value={formik.values.manufacturerCountry}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* <input list='countrys' type="text" name="manufacturerCountry"  /> */}
          <datalist id='countrys'>
            {countrysOption}
          </datalist>
          {formik.touched.manufacturerCountry && formik.errors.manufacturerCountry ? (
            <div className="text-danger">{formik.errors.manufacturerCountry}</div>
          ) : null}
        </Form.Group>

        <Form.Group controlId="sizes">
          <Form.Label>Кількість мл у пляшичці</Form.Label>
          <Form.Control
            type='text'
            name="sizes"
            value={formik.values.sizes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.sizes && formik.errors.sizes ? (
            <div className="text-danger">{formik.errors.sizes}</div>
          ) : null}
        </Form.Group>

        <Form.Group controlId="discount">
          <Form.Label>Відсоток знижки</Form.Label>
          <Form.Control
            type='text'
            name="discount"
            value={formik.values.discount || 0}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.discount && formik.errors.discount ? (
            <div className="text-danger">{formik.errors.discount}</div>
          ) : null}
        </Form.Group>

        <Form.Group controlId="enabled">
          <Form.Label>Виставити товар <RequiredIndicator /></Form.Label>
          <Form.Check
              type="checkbox"
              checked={formik.values.enabled}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
          />
          {formik.touched.enabled && formik.errors.enabled ? (
              <div className="text-danger">{formik.errors.enabled}</div>
          ) : null}
        </Form.Group>
        
        <Form.Group controlId="bottling">
          <Form.Label>Розпив <RequiredIndicator /></Form.Label>
          <Form.Check
              type="checkbox"
              checked={formik.values.bottling}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
          />
          {formik.touched.bottling && formik.errors.bottling ? (
              <div className="text-danger">{formik.errors.bottling}</div>
          ) : null}
        </Form.Group>
        {formik.values.bottling && <Form.Group controlId="quantityMl">
          <Form.Label>Кількість доступних мл</Form.Label>
          <Form.Control
            type='text'
            name="quantityMl"
            value={formik.values.quantityMl}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.quantityMl && formik.errors.quantityMl ? (
            <div className="text-danger">{formik.errors.quantityMl}</div>
          ) : null}
        </Form.Group>}
        <Form.Group controlId="toCarusel">
          <Form.Label>
            Додати до каруселі 
          </Form.Label>
          <Form.Check
            type="switch"
            id="toCarusel-checkbox"
            name="toCarusel"
            onBlur={formik.handleBlur}
            checked={formik.values.toCarusel}
            onChange={(e) => formik.setFieldValue('toCarusel', e.target.checked)}
          />
          {formik.touched.toCarusel && formik.errors.toCarusel ? (
              <div className="text-danger">{formik.errors.toCarusel}</div>
          ) : null}
        </Form.Group>

        <Button variant="dark" type="submit" className='mt-3' disabled={!formik.isValid}>
          {edit ? "Редагування продукту" : "Створити продукт"} 
        </Button>
      </Form>
    </div>
  );
};

export default ProductForm;
