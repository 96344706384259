import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styles from './CarouselSettings.module.scss'; // Імпорт SCSS модуля
import { useMessageError, useMessageSuccess } from '../../../hooks/message.hook';
import { AuthContext } from '../../../context/AuthContext';
import { Form } from 'react-bootstrap';

const CarouselSettings = () => {
  const [caruselItems, setCaruselItems] = useState([]);
  const [carusels, setCarusels] = useState([]); // Додаємо стан для зберігання всіх каруселей
  const [newSlide, setNewSlide] = useState({ itemId: '', itemType: 'blog', order: 1 });
  const [selectedCaruselId, setSelectedCaruselId] = useState('');
  const [activeCarusel, setActiveCarusel] = useState(false);
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [newCaruselTitle, setNewCaruselTitle] = useState(''); // Стан для назви нової каруселі
  const [updatedCaruselTitle, setUpdatedCaruselTitle] = useState(''); // Стан для оновленої назви каруселі
  const messageError = useMessageError();
  const messageSuccess = useMessageSuccess();
  const auth = useContext(AuthContext);

  const config = {
    headers: {}
  };

  if (auth.isAuthenticated) {
    config.headers = { "Authorization": `Bearer ${auth.token}` };
  }

  useEffect(() => {
    getCarusels();
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (selectedCaruselId) {
      fetchCaruselItems(selectedCaruselId);
    }
    getProductByCarusel();
    getBlogByCarusel();
  }, [selectedCaruselId]);

  const getCarusels = async () => {
    try {
      const response = await axios.get('/api/admin/carusels/get/all', config); 
      setCarusels(response.data.data); 
      messageSuccess('Каруселі успішно завантажено');
    } catch (error) {
      messageError('Помилка при завантаженні каруселей');
    }
  };

  const createCarusel = async () => {
    try {
      const response = await axios.post('/api/admin/carusels/create', { title: newCaruselTitle }, config);
      setCarusels([...carusels, response.data]); 
      setNewCaruselTitle(''); 
      messageSuccess('Карусель успішно створено');
    } catch (error) {
      messageError('Помилка при створенні каруселі');
    }
  };

  const updateCarusel = async (caruselId) => {
    try {
      await axios.put(`/api/admin/carusels/update/${caruselId}`, { title: updatedCaruselTitle, active: activeCarusel }, config);
      getCarusels(); 
      setUpdatedCaruselTitle(''); 
      messageSuccess('Карусель успішно оновлено');
    } catch (error) {
      messageError('Помилка при оновленні каруселі');
    }
  };

  const fetchCaruselItems = async (caruselId) => {
    try {
      const response = await axios.get(`/api/admin/carusels/${caruselId}/items`, config);
      setCaruselItems(response.data.data);
    } catch (error) {
      messageError('Помилка при завантаженні слайдів');
    }
  };

  const handleAddSlide = async () => {
    try {
      await axios.post('/api/admin/carusels/add-slide', {
        caruselId: selectedCaruselId,
        itemId: newSlide.itemId,
        itemType: newSlide.itemType,
        order: newSlide.order
      }, config);
      fetchCaruselItems(selectedCaruselId); 
      messageSuccess('Слайд успішно додано');
    } catch (error) {
      messageError('Помилка при додаванні слайду');
    }
  };

  const handleDeleteSlide = async (slideId) => {
    try {
      await axios.delete('/api/admin/carusels/delete-slide', {
        data: {
          caruselId: selectedCaruselId,
          slideId: slideId
        }
      }, config);
      fetchCaruselItems(selectedCaruselId); 
      messageSuccess('Слайд успішно видалено');
    } catch (error) {
      messageError('Помилка при видаленні слайду');
    }
  };

  const handleUpdateSlideOrder = async (slideId, newOrder) => {
    try {
      await axios.put('/api/admin/carusels/update-slide-order', {
        caruselId: selectedCaruselId,
        slideId: slideId,
        newOrder: newOrder
      }, config);
      fetchCaruselItems(selectedCaruselId); 
      messageSuccess('Порядок слайду оновлено');
    } catch (error) {
      messageError('Помилка при оновленні порядку слайду');
    }
  };

  const handleDeleteAllSlides = async () => {
    try {
      await axios.delete('/api/admin/carusels/delete-all-slides', {
        data: {
          caruselId: selectedCaruselId
        }
      }, config);
      setCaruselItems([]); 
      messageSuccess('Всі слайди успішно видалено');
    } catch (error) {
      messageError('Помилка при видаленні всіх слайдів');
    }
  };

  const getProductByCarusel = async () => {
    try {
      const data = await axios.get(`/api/admin/products/productsforcaruser`, config);
      setProducts(data.data);
      messageSuccess('Продукти успішно завантажено');
    } catch (e) {
      messageError('Помилка при завантаженні продуктів');
    }
  };

  const getBlogByCarusel = async () => {
    try {
      const data = await axios.get(`/api/admin/blog/blogforcaruser`, config);
      setBlogs(data.data.data);
      messageSuccess('Блоги успішно завантажено');
    } catch (e) {
      messageError('Помилка при завантаженні блогів');
    }
  };

  return (
    <div className={styles.carouselSettings}>
      <h1 className={styles.heading}>Налаштування каруселі</h1>

      {/* Блок для створення нової каруселі */}
      <div className={styles.createCarusel}>
        <h2 className={styles.subheading}>Створити нову карусель</h2>
        <input
          type="text"
          placeholder="Назва нової каруселі"
          value={newCaruselTitle}
          onChange={(e) => setNewCaruselTitle(e.target.value)}
          className={styles.input}
        />
        <button onClick={createCarusel} className={styles.button}>
          Створити карусель
        </button>
      </div>

      {/* Блок для оновлення існуючої каруселі */}
      <div className={styles.updateCarusel}>
        <h2 className={styles.subheading}>Оновити назву каруселі</h2>
        <select
          value={selectedCaruselId}
          onChange={(e) => { 
            const selectedCarusel = carusels.find(carusel => carusel._id === e.target.value);
            setSelectedCaruselId(e.target.value);
            setUpdatedCaruselTitle(selectedCarusel.title);
            setActiveCarusel(selectedCarusel.active);
          }}
          className={styles.select}
        >
          <option value="">Оберіть карусель для оновлення</option>
          {carusels.map((carusel) => (
            <option key={carusel._id} value={carusel._id}>
              {carusel.title}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Оновлена назва каруселі"
          value={updatedCaruselTitle}
          onChange={(e) => setUpdatedCaruselTitle(e.target.value)}
          className={styles.input}
        />
        <Form.Group controlId="active">
          <Form.Label>
            Активувати 
          </Form.Label>
          <Form.Check
            type="switch"
            id="active-checkbox"
            name="active"
            checked={activeCarusel}
            onChange={(e) => setActiveCarusel(e.target.checked)}
          />
        </Form.Group>
        <button onClick={() => updateCarusel(selectedCaruselId)} className={styles.button}>
          Оновити карусель
        </button>
      </div>

      {/* Select carousel */}
      <div className={styles.carouselSelection}>
        <label className={styles.label}>Оберіть карусель:</label>
        <select
          value={selectedCaruselId}
          onChange={(e) => setSelectedCaruselId(e.target.value)}
          className={styles.select}
        >
          <option value="">Оберіть карусель</option>
          {carusels.map((carusel) => (
            <option key={carusel._id} value={carusel._id}>
              {carusel.title}
            </option>
          ))}
        </select>
      </div>

      {/* Carousel item list */}
      <div className={styles.carouselItems}>
        <h2 className={styles.subheading}>Елементи каруселі</h2>
        {caruselItems.length > 0 ? (
          <ul className={styles.itemList}>
            {caruselItems.map((item, index) => (
              <li key={item.slideId} className={styles.item}>
                <span>
                  {item.title || item.name} - Порядок: {item.order}
                </span>
                <input
                  type="number"
                  value={item.order}
                  onChange={(e) => handleUpdateSlideOrder(item.slideId, e.target.value)}
                  className={styles.input}
                />
                <button onClick={() => handleDeleteSlide(item.slideId)} className={styles.button}>
                  Видалити
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.noItems}>Немає елементів у каруселі</p>
        )}
        {/* <button onClick={handleDeleteAllSlides} className={styles.button}>
          Видалити всі слайди
        </button> */}
      </div>

      {/* Add new slide */}
      <div className={styles.addSlide}>
        <h2 className={styles.subheading}>Додати новий слайд</h2>
        <select
          value={newSlide.itemType}
          onChange={(e) => setNewSlide({ ...newSlide, itemType: e.target.value })}
          className={styles.select}
        >
          <option value="blog">Блог</option>
          <option value="product">Продукт</option>
        </select>

        {/* Conditional dropdown for selecting blog or product */}
        {newSlide.itemType === 'product' ? (
          <select
            value={newSlide.itemId}
            onChange={(e) => setNewSlide({ ...newSlide, itemId: e.target.value })}
            className={styles.select}
          >
            <option value="">Оберіть продукт</option>
            {products.map((product) => (
              <option key={product._id} value={product._id}>
                {product.name}
              </option>
            ))}
          </select>
        ) : (
          <select
            value={newSlide.itemId}
            onChange={(e) => setNewSlide({ ...newSlide, itemId: e.target.value })}
            className={styles.select}
          >
            <option value="">Оберіть блог</option>
            {blogs.map((blog) => (
              <option key={blog._id} value={blog._id}>
                {blog.title}
              </option>
            ))}
          </select>
        )}

        <input
          type="number"
          placeholder="Порядок"
          value={newSlide.order}
          onChange={(e) => setNewSlide({ ...newSlide, order: e.target.value })}
          className={styles.input}
        />
        <button onClick={handleAddSlide} className={styles.button}>
          Додати слайд
        </button>
      </div>
    </div>
  );
};

export default CarouselSettings;
