import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHttp } from '../../../hooks/http.hook';
import { useMessageError } from '../../../hooks/message.hook';
import BurgerMenu from '../../../components/BurgerMenu/BurgerMenu';
import styles from "./Wishlist.module.scss";
import Container from '../../../components/Container/Container';
import ProductInfo from '../../../components/product/ProductInfo/ProductInfo';
import { deleteProductFromWishlist, getWishlist } from '../../../http/wishlistAPI';
import { useDispatch } from 'react-redux';
import { AuthContext } from '../../../context/AuthContext';
import { updateWishListOperation } from '../../../store/wishList/operations';

const Wishlist = () => {
    const dispatch = useDispatch();
    const { request } = useHttp();
    const messageError = useMessageError();
    const [wishlist, setWishlist] = useState([]);
    const auth = useContext(AuthContext);
    const admin = auth.permissions?.admin?.access;
    // Fetch all wishlist products from the API and update localStorage
    const fetchAll = async () => {
        try {
            const data = await request('customer/wishlist/getall', 'GET');
            setWishlist(data.data);

            // Update localStorage with the new wishlist data
            localStorage.setItem('wishlist', JSON.stringify(data.data));
        } catch (error) {
            messageError(error);
        }
    };

    // Delete product from wishlist both in the backend and in localStorage
    const deleteProduct = async (id) => {
        try {
            await deleteProductFromWishlist(id);

            // Fetch the updated wishlist after deletion
            const updatedWishlist = wishlist.products.filter(product => product._id !== id);
            const result = { ...wishlist, products: updatedWishlist }
            setWishlist(result);
            fetchAll();
            dispatch(updateWishListOperation(admin, result));
            // Update localStorage with the modified wishlist
            localStorage.setItem('wishlist', JSON.stringify(result));
        } catch (error) {
            messageError(error);
        }
    };

    useEffect(() => {
        // Initial fetch of wishlist on component mount
        fetchAll();
    }, []);

    const hasProducts = wishlist && wishlist.products && wishlist.products.length > 0;

    return (
        <section>
            <BurgerMenu />
            <Container>
                <h2 style={{ paddingTop: '50px' }}>Всі вподобані товари</h2>
                <p>Kількість: <span>{hasProducts ? wishlist.products.length : 0}</span></p>
                {!hasProducts ? (
                    <p>Немає жодних товарів у вашому списку бажань.</p>
                ) : (
                    <div className={styles.cartWrapper}>
                        {wishlist.products.map((product, index) => (
                            <div key={product._id} className={`${styles.cartItem} ${product.enabled ? "" : styles.bgDisabled}`}>
                                <div className={styles.itemIndex}><p>{index + 1}</p></div>
                                <div className={styles.itemImage}>
                                    <div className='d-flex justify-content-between'>
                                        <ProductInfo product={product} />
                                        <Button
                                            variant="danger"
                                            className={styles.itemDel}
                                            onClick={() => deleteProduct(product._id)}
                                        >
                                            X
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Container>
        </section>
    );
};

export default Wishlist;
